import React, { useEffect, useState } from 'react';
//import { GrLanguage } from 'react-icons/gr';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useBoundStore } from '../../stores';
import { langMap } from '../../constants/lang';
import trFlag from '../../assets/images/turkey.png';
import enFlag from '../../assets/images/united-kingdom.png';

//const languageOptions = [...langMap.keys()];
const languageOptions = [...langMap.keys()];

const LangSwitcher = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const changeLanguage = useBoundStore((state) => state.changeLanguage);
  const language = useBoundStore((state) => state.language);
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const selectLanguage = (language) => {
    changeLanguage(language);
    setDropdownOpen(false);
  };

  const getFlagForLanguage = (language) => {
    if (language === 'tr') {
      return trFlag;
    } else if (language === 'en') {
      return enFlag;
    }
  };

  return (
    <div className="relative">
      <div
        className="flex items-center cursor-pointer"
        onClick={toggleDropdown}
      >
        <img
          src={getFlagForLanguage(language)}
          alt={language}
          className="w-4 h-4 mr-2"
        />
        <span className="ml-2">
          {' '}
          {language === 'tr' ? 'Türkçe' : 'English'}
        </span>
        <div className="ml-1">
          {isDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
      </div>
      {isDropdownOpen && (
        <div className="absolute left-0  bg-white border border-gray-300 rounded shadow-lg z-[1]">
          <ul>
            {languageOptions.map((lang) => {
              return (
                <li
                  key={lang}
                  onClick={() => selectLanguage(lang)}
                  className="cursor-pointer py-2 px-4 hover:bg-gray-100"
                >
                  {lang === 'tr' ? 'Türkçe' : 'English'}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LangSwitcher;
