import { create } from 'zustand';

const user_string = localStorage.getItem('user');
const user_json = user_string ? JSON.parse(user_string) : null;

const permissions_string = localStorage.getItem('permissions');
const permissions_json = permissions_string
  ? JSON.parse(permissions_string)
  : [];

const useAuthStore = create((set) => ({
  isLoggedIn: user_json ? true : false,
  user: user_json ? user_json : null,
  permissions: permissions_json ? permissions_json : [],
  setUser: (value) => set({ user: value }),
  setPermissions: (value) => set({ permissions: value }),
  setIsLoggedIn: (value) => set({ isLoggedIn: value }),
  clear: () => set({ isLoggedIn: false, user: null, permissions: [] }),
  login: (user, permissions) => {
    set({ isLoggedIn: true, user, permissions });
  },
  // logout: () => set({ isLoggedIn: false, user: null, permissions: [] })
}));

export default useAuthStore;
