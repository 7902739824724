const instances = [
  {
    id: 'amir-al0',
    name: 'HPC Konya',
    config: 'algida',
  },
  {
    id: 'amir-tr0',
    name: 'AYD Konya',
    config: 'ayd',
  },
];

export default instances;
