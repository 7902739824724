export const websocket_config = {
  url: {
    algida: 'http://78.186.223.18:5000/connect-endpoint/',
    ayd: 'http://78.186.223.18:7083/connect-endpoint/',
  },
  username: 'admin',
  password: 'admin',
  broker: '/connect-broker/topic/kavi/public',
};
/*
{
    "username":"admin",
    "passcode":"admin"
}
*/
export const leaflet_config = {
  elementId: 'leafletmap',
  coordinate: [37.93382, 32.50223],
  zoom: 10,
  tileLayer: 'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
};
