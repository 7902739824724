import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import IsLoggedIn from './IsLoggedIn';
import useAuthStore from '../stores/auth-store';
import { EnterLayoutPage, LoginPage } from '../pages';

function MainContainer() {
  const { isLoggedIn } = useAuthStore();

  return (
    <Router>
      {isLoggedIn ? (
        <IsLoggedIn />
      ) : (
        <EnterLayoutPage>
          <LoginPage />
        </EnterLayoutPage>
      )}
    </Router>
  );
}

export default MainContainer;
