import { el } from 'react-date-range/dist/locale';
import { Link } from 'react-router-dom';

const DasboardItem = ({ image, alt, title, link, icon }) => {
  return (
    <Link to={link}>
      <div className="flex flex-col w-[22rem]  h-56 gap-5 bg-white border p-2 cursor-pointer hover:scale-110 transition ease 1s drop-shadow-2xl shadow-lg rounded-[29px]">
        <div className="flex justify-end items-center">
          {!icon ? (
            <img src={image} alt={alt} className="w-[80px] h-[80px] m-5" />
          ) : (
            <div className="w-[80px] h-[80px] m-5 flex justify-end items-start">
              {icon}
            </div>
          )}
        </div>
        <div className="flex justify-start font-semibold items-end text-[24px] m-3">
          {title}
        </div>
      </div>
    </Link>
  );
};

export default DasboardItem;
