import { Link } from 'react-router-dom';
import DasboardItem from '../dashboardItem/index.jsx';
import PermissionToRender from '../../Permission/PermissionToRender.jsx';

const DashboardContainer = ({ data, title }) => {
  return (
    <div className="w-full px-5 my-5">
      <h1 className="text-[30px] my-4">{title}</h1>
      <div className="flex flex-wrap justify-start max-lg:justify-center items-center gap-10">
        {data.map((el) => (
          <PermissionToRender
            instanceCanView={el.instanceCanView}
            key={el.title}
          >
            <DasboardItem
              image={el.image}
              icon={el.icon}
              alt={el.alt}
              title={el.title}
              link={el.to}
            />
          </PermissionToRender>
        ))}
      </div>
    </div>
  );
};

export default DashboardContainer;
